<template>
    <b-card>
      <b-tabs align="left" class="tab-primary margin-tabs-pudo">
        <div class="position-icon-chevron">
            <feather-icon
            icon="ChevronLeftIcon"
            size="18"
            @click="returnPudos()"
            />
        </div>
        <b-tab active>
          <template #title>
            <div class="tab-title">
              <span> {{$t('Datos generales')}} </span>
            </div>
          </template>
          <data-general ref="dataGeneral" v-if="!loading" :pudo="pudo" @refreshPudo="refreshPudo" @disabledSchedules="disabledSchedules" :mySession="mySession"></data-general>
          <div v-else>
            <div class="center-spinner">
                <b-spinner />
            </div>
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div>
              <span> {{$t('Horarios')}} </span>
            </div>
          </template>
          <schedules v-if="!loading" :schedules="pudo.schedules" @changeSchedules="changeSchedules" :disabledSchedulesForm="disabledSchedulesForm"></schedules>
          <div v-else>
            <div class="center-spinner">
                <b-spinner />
            </div>
          </div>
        </b-tab>
        <b-tab lazy v-if="showTabHistory">
          <template #title>
            <div>
              <span> {{$t('Historial de estados')}} </span>
            </div>
          </template>
          <status-history v-if="!loading" :pudo="pudo"></status-history>
          <div v-else>
            <div class="center-spinner">
                <b-spinner />
            </div>
          </div>
        </b-tab>
        <b-tab lazy v-if="showTabEmployee">
          <template #title>
            <div>
              <span> {{$t('Empleados')}} </span>
            </div>
          </template>
          <employee v-if="!loading" :pudo="pudo" @refreshUsers="refreshUsers"></employee>
          <div v-else>
            <div class="center-spinner">
                <b-spinner />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
</template>
<script>
import { environment } from '@/environments/environment'
import BaseServices from '@/store/services/index'
import DataGeneral from './tabs/DataGeneral.vue'
import Employee from './tabs/Employee.vue'
import Schedules from './tabs/Schedules.vue'
import StatusHistory from './tabs/StatusHistory.vue'
import { mapGetters } from 'vuex'
export default {
  components: { DataGeneral, Employee, Schedules, StatusHistory },
  data () {
    return {
      formattedCountry: [],
      loading: false,
      pudo: {},
      baseService: new BaseServices(this),
      showTabEmployee: false,
      showTabHistory: false,
      disabledSchedulesForm: false,
      TYPE: Object.freeze({
        COURIER: 'Courier',
        ORGANIZATION: 'Organization',
        SELLER: 'Seller'
      })
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  mounted () {
    this.getAllInitialData()
  },
  watch: {
    mySession () {
      this.loading = true
      if (this.mySession.id) this.setInitialData()
    }
  },
  methods: {
    getAllInitialData () {
      this.loading = true
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData () {
      this.sessionUser = this.$session.get('cas_user')
      this.isSeller = ['ecommerce', 'seller'].includes(this.sessionUser.role)
      this.isAdmin = ['superadmin', 'admin'].includes(this.sessionUser.role)
      this.getPudo()
    },
    getPudo () {
      const queryParams = {}
      const params = {
        id: this.$route.params.id
      }
      return this.baseService.callService('getPudoId', queryParams, params)
        .then(resp => {
          this.pudo = resp.data
          this.conditionsTabs()
          this.loading = false
        })
        .catch(err => {
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err.message}))
        })
    },
    conditionsTabs () {
      const type = this.pudo.owner.type
      const typeCourier = type === this.TYPE.COURIER
      const typeSeller = type === this.TYPE.SELLER
      const typeOrganization = type === this.TYPE.ORGANIZATION

      this.showTabHistory = !((typeCourier && !this.isAdmin) || (typeOrganization && this.isSeller))
      this.showTabEmployee = (!typeCourier && !this.isSeller) || (this.isSeller && typeSeller)
    },
    returnPudos () {
      window.open(`${environment.platformUrl}/pudos-beta`, '_blank')
    },
    changeSchedules(data) {
      this.pudo.schedules = data
    },
    refreshPudo(data) {
      this.pudo.users = data.users
      this.pudo.status_history = data.status_history
    },
    refreshUsers(data) {
      this.$refs.dataGeneral.formPudo.emails = data.length > 0 ? data.map(item => item.email) : []
    },
    disabledSchedules (disabled) {
      this.disabledSchedulesForm = disabled
    }
  }
}
</script>
<style>
.position-icon-chevron {
    position: absolute;
    top: 2rem;
}
.position-icon-chevron:hover {
    cursor: pointer;
}
.margin-tabs-pudo .nav-tabs {
    margin-left: 3rem;
    position: relative;
}
.center-spinner {
    display: flex;
    justify-content: center;
}
</style>